<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 172 172"
    style="fill: inherit;"
  >
    <g
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="inherit"><path d="M16.6625,20.64c-5.41531,0 -9.7825,4.54188 -9.7825,9.94375v117.33625c0,1.89469 1.54531,3.44 3.44,3.44h151.36c1.89469,0 3.44,-1.54531 3.44,-3.44v-117.33625c0,-5.40187 -4.36719,-9.94375 -9.7825,-9.94375zM16.6625,27.52h138.675c1.59906,0 2.9025,1.29 2.9025,3.06375v31.33625h-144.48v-31.33625c0,-1.77375 1.30344,-3.06375 2.9025,-3.06375zM65.36,34.4v20.64h82.56v-20.64zM27.52,37.84c-3.80281,0 -6.88,3.07719 -6.88,6.88c0,3.80281 3.07719,6.88 6.88,6.88c3.80281,0 6.88,-3.07719 6.88,-6.88c0,-3.80281 -3.07719,-6.88 -6.88,-6.88zM48.16,37.84c-3.80281,0 -6.88,3.07719 -6.88,6.88c0,3.80281 3.07719,6.88 6.88,6.88c3.80281,0 6.88,-3.07719 6.88,-6.88c0,-3.80281 -3.07719,-6.88 -6.88,-6.88zM72.24,41.28h68.8v6.88h-68.8zM13.76,68.8h144.48v75.68h-144.48zM24.08,79.12v24.08h75.68v-24.08zM110.08,79.12v55.04h37.84v-55.04zM30.96,86h61.92v10.32h-61.92zM116.96,86h24.08v41.28h-24.08zM24.08,110.08v24.08h75.68v-24.08zM30.96,116.96h61.92v10.32h-61.92z"></path></g></g></svg>

</template>

<script>
export default {
};
</script>
