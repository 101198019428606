<template>
  <li
    @click="$emit('clicked')"
    class="hamburger_nav_list_item"
    v-if="canView"
  >
    <button class="inline-flex gap-2 items-center" v-if="expandable">
          <chevron-left-icon
          :style="`height:${size};width:${size};fill:${colour};`"
          ></chevron-left-icon>
          <span >{{ name }}</span>
    </button>
          <a :href="link" class="flex gap-4 items-center" :class="font" v-else>
            <span
                class="
                  rounded-full
                  h-6
                  w-6
                  text-xxs
                  flex
                  justify-center
                  items-center
                "
                :class="`${font} bg-${navigation_settings.settings.main.secondary_background_colour.class.replace('_', '-')} text-${navigation_settings.settings.main.secondary_link.colour.class.replace('_', '-')}`"
                v-if="number"
              >
                {{ number }}
              </span>
          {{
            name
          }}
          <span class="hamburger-icon">
            <component
                v-bind:is="icon"
                :style="`height:20;width:20;fill:${colour};`"
                v-if="icon"
              ></component>
          </span>
          </a>
        </li>
</template>

<script>
import CartIcon from "../icons/CartIcon.vue";
import ChevronLeftIcon from "../ChevronLeftIcon.vue";
import CustomerOrderIcon from "../icons/CustomerOrderIcon.vue";
import BrandIcon from "../icons/BrandIcon.vue";
import CategoryIcon from "../icons/CategoryIcon.vue";
import CompanyIcon from "../icons/CompanyIcon.vue";
import ConsignmentIcon from "../icons/ConsignmentIcon.vue";
import CouponIcon from "../icons/CouponIcon.vue";
import DashboardIcon from "../icons/DashboardIcon.vue";
import FacebookIcon from "../icons/FacebookIcon.vue";
import GiftCertificateIcon from "../icons/GiftCertificateIcon.vue";
import HomeIcon from "../icons/HomeIcon.vue";
import InventoryIcon from "../icons/InventoryIcon.vue";
import InventoryItemIcon from "../icons/InventoryItemIcon.vue";
import InventorySetIcon from "../icons/InventorySetIcon.vue";
import LogIcon from "../icons/LogIcon.vue";
import NewsletterIcon from "../icons/NewsletterIcon.vue";
import NoteIcon from "../icons/NoteIcon.vue";
import PageIcon from "../icons/PageIcon.vue";
import PageItemIcon from "../icons/PageItemIcon.vue";
import PaymentIcon from "../icons/PaymentIcon.vue";
import PreorderIcon from "../icons/PreorderIcon.vue";
import ProductIcon from "../icons/ProductIcon.vue";
import ProductSetIcon from "../icons/ProductSetIcon.vue";
import ProductSkuIcon from "../icons/ProductSkuIcon.vue";
import ServiceIcon from "../icons/ServiceIcon.vue";
import ServicesIcon from "../icons/ServicesIcon.vue";
import SupportIcon from "../icons/SupportIcon.vue";
import SupplierIcon from "../icons/SupplierIcon.vue";
import SupplierOrderIcon from "../icons/SupplierOrderIcon.vue";
import SpecialIcon from "../icons/SpecialIcon.vue";
import WarehouseIcon from "../icons/WarehouseIcon.vue";
import TransactionIcon from "../icons/TransactionIcon.vue";
import TransportationCompanyIcon from "../icons/TransportationCompanyIcon.vue";
import UserIcon from "../icons/UserIcon.vue";
import TagIcon from "../icons/TagIcon.vue";
import TagsIcon from "../icons/TagsIcon.vue";
import WarehouseInventoryIcon from "../icons/WarehouseInventoryIcon.vue";
import WebIcon from "../icons/WebIcon.vue";
import XeroIcon from "../icons/XeroIcon.vue";
import Authenticator from '../../services/authenticator'

export default {
  components: {
    CartIcon,
    ChevronLeftIcon,
    CustomerOrderIcon,
    BrandIcon,
    CategoryIcon,
    CompanyIcon,
    ConsignmentIcon,
    CouponIcon,
    DashboardIcon,
    FacebookIcon,
    GiftCertificateIcon,
    HomeIcon,
    InventoryIcon,
    InventoryItemIcon,
    InventorySetIcon,
    LogIcon,
    NewsletterIcon,
    NoteIcon,
    PageIcon,
    PaymentIcon,
    PreorderIcon,
    ProductIcon,
    ProductSetIcon,
    ProductSkuIcon,
    SupportIcon,
    SupplierIcon,
    SupplierOrderIcon,
    SpecialIcon,
    WarehouseIcon,
    TransactionIcon,
    TransportationCompanyIcon,
    SupplierIcon,
    UserIcon,
    TagIcon,
    TagsIcon,
    ServiceIcon,
    ServicesIcon,
    PageItemIcon,
    WarehouseInventoryIcon,
    WebIcon,
    XeroIcon,
  },
  props: {
    size: String,
    colour: String,
    name: String,
    link: String,
    expandable: Boolean,
    icon: String,
    number: Number,
    user: Object,
    permission: Array,
    navigation_settings: Object,
    font: String,
  },
  computed: {
    canView() {
        
      
      if (!this.permission || this.user?.admin) {
            return true
        }

        return this.permission.reduce((approved, permission) => approved = Authenticator.can('view', permission, this.user) ? true : approved, false)
    }
  }
};
</script>