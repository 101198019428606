<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 172 172"
    style="fill: inherit;"
  >
    <g
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="inherit">
        <path
          d="M134.4825,0c-4.93156,0 -9.245,3.82969 -9.46,8.9225h-0.1075v0.3225l-6.45,66.7575c-0.47031,-0.215 -0.98094,-0.33594 -1.505,-0.3225h-3.44v-20.64c0.01344,-1.19594 -0.60469,-2.29781 -1.59906,-2.92937c-1.00781,-0.645 -2.27094,-0.71219 -3.34594,-0.18813l-50.095,23.3275v-16.77c-0.01344,-1.19594 -0.645,-2.31125 -1.67969,-2.92937c-1.03469,-0.61813 -2.31125,-0.645 -3.37281,-0.08063l-51.6,27.52c-1.11531,0.59125 -1.81406,1.74688 -1.8275,3.01v82.56c0,1.89469 1.54531,3.44 3.44,3.44h165.12c0.95406,0 1.85438,-0.38969 2.51281,-1.08844c0.645,-0.68531 0.98094,-1.6125 0.92719,-2.56656l-9.675,-158.885c0,-0.06719 0,-0.14781 0,-0.215c-0.51062,-5.06594 -4.43437,-9.245 -9.5675,-9.245zM134.4825,6.88h18.275c1.02125,0 2.41875,1.30344 2.6875,3.01l9.46,155.23h-158.025v-77.0775l44.72,-23.865v14.9425c0,1.89469 1.54531,3.44 3.44,3.44h3.44c0.52406,0.01344 1.03469,-0.1075 1.505,-0.3225l46.655,-21.8225v18.705c0,1.89469 1.54531,3.44 3.44,3.44h3.44v44.72c-0.09406,1.89469 1.38406,3.50719 3.27875,3.60125c1.89469,0.09406 3.50719,-1.38406 3.60125,-3.27875v-0.3225l11.2875,-117.7125c0.04031,-0.1075 0.08063,-0.215 0.1075,-0.3225c0,-1.59906 0.84656,-2.365 2.6875,-2.365zM24.08,92.88v17.2h10.32v-17.2zM55.04,92.88v17.2h10.32v-17.2zM86,92.88v17.2h10.32v-17.2zM24.08,123.84v17.2h10.32v-17.2zM55.04,123.84v17.2h10.32v-17.2zM86,123.84v17.2h10.32v-17.2z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
};
</script>
