<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 172 172"
    style="fill: inherit;"
  >
    <g
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="inherit">
        <path d="M86.0739,10.32c-0.3946,-0.00888 -0.78778,0.05021 -1.16234,0.17469l-72.24,24.08c-1.40503,0.46875 -2.35234,1.78415 -2.35156,3.26531v120.4c0.00019,1.89978 1.54022,3.43981 3.44,3.44h20.06219c0.37149,0.0614 0.75054,0.0614 1.12203,0h102.07797c0.37149,0.0614 0.75054,0.0614 1.12203,0h0.05375h20.04203c1.89978,-0.00019 3.43981,-1.54022 3.44,-3.44v-120.4c0.00077,-1.48116 -0.94653,-2.79656 -2.35156,-3.26531l-72.24,-24.08c-0.3275,-0.10878 -0.66951,-0.16767 -1.01453,-0.17469zM86,17.38812l68.8,22.93109v114.48078h-13.76v-96.32c-0.00019,-1.89978 -1.54022,-3.43981 -3.44,-3.44h-103.2c-1.89978,0.00019 -3.43981,1.54022 -3.44,3.44v96.32h-13.76v-114.48078zM37.84,61.92h96.32v92.88h-10.32v-30.96c-0.00019,-1.89978 -1.54022,-3.43981 -3.44,-3.44h-13.76v-30.96c-0.00019,-1.89978 -1.54022,-3.43981 -3.44,-3.44h-34.4c-1.89978,0.00019 -3.43981,1.54022 -3.44,3.44v30.96h-13.76c-1.89978,0.00019 -3.43981,1.54022 -3.44,3.44v30.96h-10.32zM72.24,92.88h27.52v27.52h-13.76h-13.76zM55.04,127.28h13.76h13.76v27.52h-27.52zM89.44,127.28h13.76h13.76v27.52h-27.52z"></path></g></g></svg>
</template>

<script>
export default {
};
</script>
