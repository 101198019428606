<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 172 172"
      style="fill: inherit;"
    >
      <g
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <path d="M0,172v-172h172v172z" fill="none"></path>
        <g id="Layer_1">
          <path
            d="M16.125,45.6875l26.875,-26.875h86l26.875,26.875"
            fill=""
          ></path>
          <rect
            x="12"
            y="34"
            transform="scale(1.34375,1.34375)"
            width="104"
            height="80"
            fill=""
          ></rect>
          <path
            d="M158.69688,42.86563l-26.875,-26.875c-0.67187,-0.80625 -1.74688,-1.20937 -2.82188,-1.20937h-86c-1.075,0 -2.15,0.40313 -2.82187,1.20937l-26.875,26.875c-0.80625,0.67188 -1.20937,1.74688 -1.20937,2.82187v107.5c0,2.28438 1.74688,4.03125 4.03125,4.03125h139.75c2.28438,0 4.03125,-1.74687 4.03125,-4.03125v-107.5c0,-1.075 -0.40312,-2.15 -1.20937,-2.82187zM146.2,41.65625h-56.16875v-18.8125h37.35625zM44.6125,22.84375h37.35625v18.8125h-56.16875zM151.84375,149.15625h-131.6875v-99.4375h131.6875z"
            fill="inherit"
          ></path>
        </g>
      </g>
    </svg>
</template>

<script>
export default {
};
</script>
