
<template>
  <div class="w-100 shadow-lg" ref="container" :class="`bg-${this.navigation_settings.settings.main.primary_background_colour.class} text-${this.navigation_settings.settings.main.primary_link.colour.class}`">
    <div class="h-full" :class="{ 'section': !admin }">
      <div class="flex gap-4" :style="`margin-top:${offsetHeight}px;`">
        <div class="flex flex-col gap-1 pl-8 pr-4 pb-4">
          <a
            :href="`/services/${service.slug}`"
            v-for="service in services"
            :key="service.id"
          >
            {{ service.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    services: {
      type: Array
    },
    navigation_settings: Object,
    admin: Number,
  },
  data() {
    return {
      offsetHeight: 0
    };
  },
  methods: {},
  mounted() {
    this.offsetHeight = document.getElementById('main_nav_primary_row').offsetHeight + 20
  }
};
</script>
<style scoped>
</style>
