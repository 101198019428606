
<template>
  <div class="w-100 shadow-lg" ref="container" :class="`bg-${navigation_settings.settings.main.primary_background_colour.class} text-${navigation_settings.settings.main.primary_link.colour.class}`" id="category__nav">
    <div class="h-full" :class="{ 'section': !admin }">
      <div class="flex gap-4" :style="`margin-top:${offsetHeight}px;`">
        <div class="flex flex-col gap-1 pl-8 pr-4 pb-4">
          <a href="/shop" class="hover:underline" :class="font" v-if="all_products_link"> All Products </a>
          <a href="/shop/specials" class="hover:underline" :class="font" v-if="all_products_link"> Specials </a>
          <a
            :href="`/shop/categories/${category.slug}`"
            v-for="category in categories"
            :key="category.id"
            class="hover:underline"
            :class="`${font} ${category.id == selected_category_id ? 'font-bold' : ''}`"
            @mouseover="selected_category_id = category.id"
            @focus="selected_category_id = category.id"
          >
            {{ category.name }}
          </a>
        </div>
        <transition name="fade">
          <div
            class="flex-1 bg-white grid"
            :style="selectedCategoryStyle"
            v-if="current_category"
          >
            <div class="h-full flex w-full"  :style="`background: rgba(${backgroundColour.toRgb().r}, ${backgroundColour.toRgb().g}, ${backgroundColour.toRgb().b}, 0.6)`">
              <div
                class="h-full flex flex-col gap-4 p-4 "
                
              >
                <a
                  :href="`/shop/categories/${current_category.slug}`"
                  class="text-xl font-bold hover:underline"
                  :class="font"
                >
                  {{ current_category.name }}
                </a>
                <div class="flex flex-col flex-wrap content-start gap-1 h-64">
                  <a
                    :href="`/shop/categories/${c.slug}`"
                    v-for="c in sortedCurrentCategories"
                    :key="c.id"
                    class="text-sm mr-8 hover:underline"
                    :class="font"

                  >
                    {{ c.name }}
                  </a>
                </div>
              </div>
              
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import tinycolor from "tinycolor2";
import ImageService from "../services/imageService";

export default {
  components: {},
  props: {
    categories: {
      type: Array,
      default: []
    },
    navigation_settings: Object,
    admin: Number,
    all_products_link: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selected_category_id: null,
      offsetHeight: 0
    };
  },
  computed: {
    font() {
      return this.navigation_settings.settings.main.primary_link_font
    },
    backgroundColour() {
      return tinycolor(this.navigation_settings.settings.main.primary_background_colour.hex ?? '#000000');
    },
    textColorClass() {
      return this.backgroundColour.isDark() ? 'text-white' : 'text-gray-900'
    }, 
    current_category() {
      return this.categories.find((c) => c.id == this.selected_category_id);
    },
    sortedCurrentCategories() {
      return this.current_category?.categories?.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }) ?? []
    },  
    selectedCategoryStyle() {
      const imageStyle = this.current_category?.image
        ? `background: url(${ImageService.url(this.current_category.image)}) no-repeat ${this.current_category.image.position};
  background-size: cover;`
        : "";

      return `${imageStyle}`;
    },
  },
  methods: {},
  mounted() {
    this.offsetHeight = document.getElementById('main_nav_primary_row').offsetHeight + 20
  }
};
</script>
<style scoped>
</style>
