
<template>
  <div class="w-100 shadow-lg" ref="container" :class="`bg-${this.navigation_settings.settings.main.primary_background_colour.class} text-${this.navigation_settings.settings.main.primary_link.colour.class}`">
    <div class="h-full" :class="{ 'section': !admin }">
      <div class="flex gap-4" :style="`margin-top:${offsetHeight}px;`">
        <div class="flex flex-col gap-1 pl-8 pr-4 pb-4">
          <a :href="`/${page.slug}`" class="hover:font-bold"> {{ page.name }}</a>
          <a
            :href="`/${p.slug}`"
            v-for="p in pages"
            :key="p.id"
            :class="{ 'font-bold': p.id == selected_page_id }"
            @mouseover="selected_page_id = p.id"
            @focus="selected_page_id = p.id"
          >
            {{ p.name }}
          </a>
        </div>
        <transition name="fade">
          <div
            class="flex-1 bg-white grid"
            :style="selectedPageStyle"
            v-if="current_page"
          >
            <div class="h-full flex w-full" :style="`background: rgba(${this.backgroundColour.toRgb().r}, ${this.backgroundColour.toRgb().g}, ${this.backgroundColour.toRgb().b}, 0.6)`">
              <div
                class="h-full flex flex-col gap-4 p-4"
                
              >
                <a
                  :href="`/${current_page.slug}`"
                  class="text-xl font-bold hover:underline"
                  :class="textColorClass"
                >
                  {{ current_page.name }}
                </a>
                <div class="flex flex-col flex-wrap content-start gap-1 h-64">
                  <a
                    :href="`/${c.slug}`"
                    v-for="c in sortedCurrentPages"
                    :key="c.id"
                    class="hover:underline text-sm mr-8"
                    :class="textColorClass"
                  >
                    {{ c.name }}
                  </a>
                </div>
              </div>
              
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import tinycolor from "tinycolor2";
import ImageService from "../services/imageService";
export default {
  components: {},
  props: {
    page: {
      type: Object
    },
    navigation_settings: Object,
    admin: Number,
  },
  data() {
    return {
      selected_page_id: null,
      offsetHeight: 0
    };
  },
  computed: {
    pages() {
      return this.page.pages
    },  
    backgroundColour() {
      return tinycolor(this.navigation_settings.settings.main.primary_background_colour.hex ?? '#000000');
    },
    textColorClass() {
      return this.backgroundColour.isDark() ? 'text-white' : 'text-gray-900'
    }, 
    current_page() {
      return this.pages.find((c) => c.id == this.selected_page_id);
    },
    sortedCurrentPages() {
      return this.current_page?.pages?.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }) ?? []
    },  
    selectedPageStyle() {
      const imageStyle = this.current_page?.image
        ? `background: url(${ImageService.url(this.current_page.image)}) no-repeat ${this.current_page.image.position};
  background-size: cover;`
        : "";

      return `${imageStyle}`;
    },
  },
  methods: {},
  mounted() {
    this.offsetHeight = document.getElementById('main_nav_primary_row').offsetHeight + 20
  }
};
</script>
<style scoped>
</style>
