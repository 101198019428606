<template>
  <li
    class="
      p-4
      flex
      items-center
      justify-end
      gap-4
      rounded mb-8
    "
    
  >
  <button class="inline-flex gap-2 items-center" @click="$emit('clicked')">
    Back
    <chevron-right-icon
      :style="`height:${size};width:${size};fill:${colour};`"
    ></chevron-right-icon>
  </button>
  </li>
</template>

<script>
import ChevronRightIcon from "../ChevronRightIcon.vue";

export default {
  components: {
    ChevronRightIcon,
  },
  props: {
    size: String,
    colour: String,
  }
};
</script> 