<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 172 172"
    style="fill:inherit;"
  >
    <g
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none" stroke="none"></path>
      <g
        id="original-icon"
        fill="inherit"
        stroke="none"
        opacity="0"
        visibility="hidden"
      >
        <path
          d="M98.47,6.88c-1.16906,0.01344 -2.35156,0.06719 -3.5475,0.215c-2.37844,0.30906 -4.98531,1.11531 -6.9875,3.1175l-78.1525,78.1525c-3.85656,3.85656 -3.85656,10.22594 0,14.0825l59.77,59.77c3.85656,3.85656 10.22594,3.85656 14.0825,0l78.1525,-78.1525c1.98875,-1.98875 2.80844,-4.6225 3.1175,-6.9875c0.30906,-2.365 0.215,-4.70312 0.215,-6.9875v-51.17c0,-6.61125 -5.42875,-12.04 -12.04,-12.04h-51.17c-1.11531,0 -2.27094,-0.01344 -3.44,0zM98.5775,13.76c1.00781,-0.02687 2.16344,0 3.3325,0h51.17c2.88906,0 5.16,2.27094 5.16,5.16v51.17c0,2.39188 -0.01344,4.55531 -0.215,6.1275c-0.20156,1.57219 -0.52406,2.35156 -1.075,2.9025l-78.1525,78.26c-1.22281,1.22281 -3.18469,1.22281 -4.4075,0l-0.43,-0.5375l-59.34,-59.2325c-1.22281,-1.22281 -1.22281,-3.18469 0,-4.4075l78.26,-78.1525c0.5375,-0.5375 1.42438,-0.87344 3.01,-1.075c0.79281,-0.1075 1.67969,-0.18812 2.6875,-0.215zM134.16,24.08c-7.56531,0 -13.76,6.19469 -13.76,13.76c0,7.56531 6.19469,13.76 13.76,13.76c7.56531,0 13.76,-6.19469 13.76,-13.76c0,-7.56531 -6.19469,-13.76 -13.76,-13.76zM134.16,30.96c3.84313,0 6.88,3.03688 6.88,6.88c0,3.84313 -3.03687,6.88 -6.88,6.88c-3.84312,0 -6.88,-3.03687 -6.88,-6.88c0,-3.84312 3.03688,-6.88 6.88,-6.88z"
        ></path>
      </g>
      <g id="subtracted-icon" fill="inherit" stroke="none">
        <path
          d="M101.91,6.88h51.17c6.61125,0 12.04,5.42875 12.04,12.04v51.17c0,2.28438 0.09406,4.6225 -0.215,6.9875c-0.30906,2.365 -1.12875,4.99875 -3.1175,6.9875l-78.1525,78.1525c-3.85656,3.85656 -10.22594,3.85656 -14.0825,0l-59.77,-59.77c-3.85656,-3.85656 -3.85656,-10.22594 0,-14.0825l78.1525,-78.1525c2.00219,-2.00219 4.60906,-2.80844 6.9875,-3.1175c1.19594,-0.14781 2.37844,-0.20156 3.5475,-0.215c1.16906,-0.01344 2.32469,0 3.44,0zM95.89,13.975c-1.58562,0.20156 -2.4725,0.5375 -3.01,1.075l-78.26,78.1525c-1.22281,1.22281 -1.22281,3.18469 0,4.4075l59.34,59.2325l0.43,0.5375c1.22281,1.22281 3.18469,1.22281 4.4075,0l78.1525,-78.26c0.55094,-0.55094 0.87344,-1.33031 1.075,-2.9025c0.20156,-1.57219 0.215,-3.73562 0.215,-6.1275v-51.17c0,-2.88906 -2.27094,-5.16 -5.16,-5.16h-51.17c-1.16906,0 -2.32469,-0.02687 -3.3325,0c-1.00781,0.02688 -1.89469,0.1075 -2.6875,0.215zM147.92,37.84c0,7.56531 -6.19469,13.76 -13.76,13.76c-7.56531,0 -13.76,-6.19469 -13.76,-13.76c0,-7.56531 6.19469,-13.76 13.76,-13.76c7.56531,0 13.76,6.19469 13.76,13.76zM127.28,37.84c0,3.84313 3.03688,6.88 6.88,6.88c3.84313,0 6.88,-3.03687 6.88,-6.88c0,-3.84312 -3.03687,-6.88 -6.88,-6.88c-3.84312,0 -6.88,3.03688 -6.88,6.88z"
        ></path>
      </g>
      <g fill="inherit" stroke="none">
        <g>
          <g
            id="Слой_2"
            font-family="Inter, sans-serif"
            font-weight="400"
            font-size="16"
            text-anchor="start"
            visibility="hidden"
          ></g>
          <g
            id="Android_x5F_4"
            font-family="Inter, sans-serif"
            font-weight="400"
            font-size="16"
            text-anchor="start"
            visibility="hidden"
          ></g>
          <g
            id="Android_x5F_5"
            font-family="Inter, sans-serif"
            font-weight="400"
            font-size="16"
            text-anchor="start"
            visibility="hidden"
          ></g>
          <g
            id="Windows_x5F_8"
            font-family="Inter, sans-serif"
            font-weight="400"
            font-size="16"
            text-anchor="start"
            visibility="hidden"
          ></g>
          <g
            id="Windows_x5F_10"
            font-family="Inter, sans-serif"
            font-weight="400"
            font-size="16"
            text-anchor="start"
            visibility="hidden"
          ></g>
          <g
            id="Color"
            font-family="Inter, sans-serif"
            font-weight="400"
            font-size="16"
            text-anchor="start"
            visibility="hidden"
          ></g>
          <g
            id="IOS"
            font-family="Inter, sans-serif"
            font-weight="400"
            font-size="16"
            text-anchor="start"
            visibility="hidden"
          ></g>
          <g id="IOS_copy">
            <path
              d="M65.19236,116.69343c-0.6863,0 -1.37261,-0.34315 -2.05891,-0.6863c-1.02946,-0.6863 -1.71576,-2.40206 -1.37261,-3.77467l5.83358,-22.30487l-17.50074,-14.41238c-1.37261,-0.6863 -1.71576,-2.40206 -1.37261,-3.77467c0.34315,-1.37261 1.71576,-2.40206 3.08837,-2.40206l22.99117,-1.37261l8.23564,-21.61856c0.6863,-0.6863 2.05891,-1.71576 3.43152,-1.71576c1.37261,0 2.74521,1.02946 3.08837,2.05891l8.23564,21.61856l22.99117,1.37261c1.37261,0 2.74521,1.02946 3.08837,2.40206c0.34315,1.37261 0,2.74521 -1.02946,3.77467l-17.84389,14.41238l5.83358,22.30487c0.34315,1.37261 0,2.74521 -1.37261,3.77467c-1.02946,0.6863 -2.74521,1.02946 -3.77467,0l-19.2165,-12.69662l-19.2165,12.35347c-0.6863,0.34315 -1.37261,0.6863 -2.05891,0.6863zM86.46777,96.10432c0.6863,0 1.37261,0.34315 1.71576,0.6863l13.72607,8.92195l-4.11782,-15.78498c-0.34315,-1.37261 0,-2.74521 1.02946,-3.43152l12.69662,-10.29455l-16.47129,-1.02946c-1.37261,0 -2.40206,-1.02946 -3.08837,-2.05891l-5.49043,-15.44183l-5.83358,15.09868c-0.6863,1.37261 -1.71576,2.05891 -3.08837,2.40206l-16.47129,0.6863l12.69662,10.29455c1.02946,0.6863 1.37261,2.40206 1.02946,3.43152l-4.11782,15.78498l13.72607,-8.92195c0.6863,0 1.37261,-0.34315 2.05891,-0.34315z"
            ></path>
          </g>
        </g>
        <g opacity="0">
          <g
            id="IOS"
            font-family="Inter, sans-serif"
            font-weight="400"
            font-size="16"
            text-anchor="start"
            visibility="hidden"
          ></g>
          <g id="IOS_copy">
            <path
              d="M121.37271,62.97188l-18.87335,-1.02946l-6.86304,-17.50074c-1.02946,-3.77467 -5.14728,-6.51988 -9.2651,-6.51988c-4.11782,0 -8.23564,2.74521 -9.60825,6.51988l-6.86304,17.50074l-18.5302,1.02946c-4.11782,0 -7.89249,3.08837 -9.2651,6.86304c-1.37261,4.11782 0,8.5788 3.43152,11.32401l14.41238,11.66716l-4.80413,17.84389c-1.02946,4.11782 0.34315,8.5788 3.77467,10.98086c2.05891,1.37261 4.11782,2.05891 6.17673,2.05891c2.05891,0 3.77467,-0.6863 5.49043,-1.71576l15.44183,-9.9514l15.78498,9.9514c1.71576,1.02946 3.43152,1.71576 5.49043,1.71576c2.05891,0 4.11782,-0.6863 6.17673,-2.05891c3.43152,-2.40206 5.14728,-6.86304 3.77467,-10.98086l-4.46097,-17.84389l14.41238,-11.66716c3.43152,-2.74521 4.46097,-7.20619 3.43152,-11.32401c-1.37261,-3.77467 -5.14728,-6.86304 -9.2651,-6.86304zM106.96033,116.84672c0.34315,0 0.34315,0 0.6863,0v0c-0.34315,0 -0.34315,0 -0.6863,0zM105.58772,116.16041c0.34315,0.34315 0.6863,0.34315 1.02946,0.34315c-0.34315,0 -0.6863,0 -1.02946,-0.34315zM108.33294,116.84672h0.34315z"
            ></path>
          </g>
        </g>
      </g>
      <path
        d="M48.5973,117v-72.36845h75.4027v72.36845z"
        id="overlay-drag"
        fill="inherit"
        stroke="none"
        opacity="0"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
};
</script>
