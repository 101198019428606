<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 172 172"
    style="fill: inherit;"
  >
    <g
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="inherit">
        <path
          d="M154.8,6.88c-3.80281,0 -6.88,3.07719 -6.88,6.88c0,1.00781 0.25531,1.935 0.645,2.795l-27.52,38.485c-0.215,-0.01344 -0.43,0 -0.645,0c-1.37062,0 -2.6875,0.37625 -3.7625,1.075l-23.7575,-11.825c-0.22844,-3.60125 -3.225,-6.45 -6.88,-6.45c-3.80281,0 -6.88,3.07719 -6.88,6.88c0,0.36281 0.05375,0.72563 0.1075,1.075l-25.155,19.995c-0.76594,-0.29562 -1.59906,-0.43 -2.4725,-0.43c-3.44,0 -6.26187,2.51281 -6.7725,5.805l-23.435,9.46c-1.16906,-0.91375 -2.60687,-1.505 -4.1925,-1.505c-3.80281,0 -6.88,3.07719 -6.88,6.88c0,3.80281 3.07719,6.88 6.88,6.88c3.46688,0 6.30219,-2.56656 6.7725,-5.9125l23.435,-9.3525c1.16906,0.91375 2.60688,1.505 4.1925,1.505c3.80281,0 6.88,-3.07719 6.88,-6.88c0,-0.36281 -0.05375,-0.72562 -0.1075,-1.075l25.155,-19.995c0.76594,0.29563 1.59906,0.43 2.4725,0.43c1.37063,0 2.6875,-0.37625 3.7625,-1.075l23.7575,11.825c0.22844,3.60125 3.225,6.45 6.88,6.45c3.80281,0 6.88,-3.07719 6.88,-6.88c0,-1.00781 -0.25531,-1.935 -0.645,-2.795l27.52,-38.485c0.215,0.01344 0.43,0 0.645,0c3.80281,0 6.88,-3.07719 6.88,-6.88c0,-3.80281 -3.07719,-6.88 -6.88,-6.88zM141.04,51.6v120.4h27.52v-120.4zM147.92,58.48h13.76v106.64h-13.76zM72.24,82.56v89.44h27.52v-89.44zM79.12,89.44h13.76v75.68h-13.76zM106.64,99.76v72.24h27.52v-72.24zM113.52,106.64h13.76v58.48h-13.76zM37.84,110.08v61.92h27.52v-61.92zM44.72,116.96h13.76v48.16h-13.76zM3.44,123.84v48.16h27.52v-48.16zM10.32,130.72h13.76v34.4h-13.76z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
};
</script>
