<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 172 172"
    style="fill: inherit;"
  >
    <g
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="inherit">
        <path
          d="M24.08,6.88v158.24h123.84v-3.44v-108.66906l-47.52844,-46.13094zM30.96,13.76h65.36v44.72h44.72v99.76h-110.08zM103.2,19.19547l33.39219,32.40453h-33.39219zM82.90937,80.61828c-7.6884,0 -11.45547,8.20279 -11.45547,17.69719c0,9.546 3.09708,17.90547 10.99188,17.90547c6.6048,0 11.24719,-5.31641 11.24719,-18.42281c0,-7.9464 -2.36935,-17.17641 -10.7836,-17.17985zM111.8336,80.71906c-7.3788,0 -14.6063,5.26347 -14.7611,17.90547c-0.1032,5.9856 1.49532,11.14587 4.48813,14.03547c2.322,2.322 5.31883,3.46015 9.29203,3.46015c2.5284,0 5.72626,-0.62377 7.22266,-1.24297c-0.00344,0 -0.00344,-18.57734 0,-18.57734h-9.33906v5.52281h3.09735v8.41188c-0.26144,0.1032 -0.73127,0.15453 -1.35047,0.15453c-3.1476,0 -6.38953,-2.99549 -6.38953,-11.35469c0,-9.0816 3.65822,-12.22812 8.25062,-12.22812c1.7028,0 2.99038,0.26338 3.97078,0.77938l1.18922,-5.83188c-1.08016,-0.516 -3.03902,-1.03469 -5.67062,-1.03469zM53.92469,81.03485v34.77625h15.68828v-5.72438h-8.92922v-29.05187zM82.65406,86.49719c3.04096,0 3.9775,5.5212 3.9775,11.61c0,7.0692 -1.08919,12.22813 -3.92375,12.22813c-2.73136,0 -4.23281,-4.02426 -4.23281,-11.91906c0,-7.47856 1.49242,-11.91562 4.17906,-11.91906z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
};
</script>
