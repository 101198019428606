<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 172 172"
    style="fill: inherit;"
  >
    <g
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="inherit">
        <path
          d="M17.2,37.84c-5.65719,0 -10.32,4.66281 -10.32,10.32v75.68c0,5.65719 4.66281,10.32 10.32,10.32h101.38594c4.31344,0 8.47906,-1.6125 11.65031,-4.54187l0.14781,-0.13438l31.86031,-36.34844l-0.14781,0.16125c4.00437,-4.00438 4.00437,-10.58875 0,-14.59313l0.16125,0.16125l-31.87375,-36.34844l-0.14781,-0.13437c-3.17125,-2.91594 -7.33687,-4.54188 -11.65031,-4.54188zM17.2,44.72h101.38594c2.58,0 5.0525,0.98094 6.96063,2.70094l31.605,36.06625l0.08062,0.08063c1.37063,1.37062 1.37063,3.49375 0,4.86437l-0.08062,0.08063l-31.605,36.05281c-1.90813,1.73344 -4.38063,2.71437 -6.96063,2.71437h-101.38594c-1.935,0 -3.44,-1.505 -3.44,-3.44v-75.68c0,-1.94844 1.49156,-3.44 3.44,-3.44zM127.28,72.24c-7.56531,0 -13.76,6.19469 -13.76,13.76c0,7.56531 6.19469,13.76 13.76,13.76c7.56531,0 13.76,-6.19469 13.76,-13.76c0,-7.56531 -6.19469,-13.76 -13.76,-13.76zM127.28,79.12c3.84313,0 6.88,3.03688 6.88,6.88c0,3.84313 -3.03687,6.88 -6.88,6.88c-3.84312,0 -6.88,-3.03687 -6.88,-6.88c0,-3.84312 3.03688,-6.88 6.88,-6.88z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
};
</script>
