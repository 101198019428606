import './bootstrap';

import { createApp } from "vue/dist/vue.esm-bundler";
import ShoppingCart from './components/ShoppingCart.vue'
import HamburgerMenu from './components/HamburgerMenu.vue'
import PagesNavigation from './components/PagesNavigation.vue'
import ServicesNavigation from './components/ServicesNavigation.vue'
import CategoriesNavigation from './components/CategoriesNavigation.vue'
import * as Sentry from "@sentry/vue";
const app = createApp({
   components: {
      ShoppingCart,
      HamburgerMenu,
      CategoriesNavigation,
      PagesNavigation,
      ServicesNavigation,
   }
})
app.config.globalProperties.eventBus = window.eventBus
app.mount('#header');