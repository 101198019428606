<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 172 172"
    style="fill: inherit;"
  >
    <g
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="inherit">
        <path
          d="M13.76,13.76v144.48h108.06437l36.41563,-36.41563v-1.42437v-106.64zM20.64,20.64h130.72v96.32h-34.4v34.4h-96.32zM48.16,51.6v6.88h79.12v-6.88zM48.16,72.24v6.88h79.12v-6.88zM48.16,92.88v6.88h79.12v-6.88zM123.84,123.84h22.65563l-22.65563,22.65563z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
};
</script>