<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 172 172"
    style="fill: inherit;"
  >
    <g
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="inherit">
        <path d="M24.08,6.88v158.24h123.84v-114.9175l-0.9675,-1.075l-41.28,-41.28l-1.075,-0.9675zM30.96,13.76h68.8v27.52h-48.16v6.88h48.16v6.88h41.28v103.2h-110.08zM106.64,18.705l29.455,29.455h-29.455zM51.6,75.68v6.88h10.32v-6.88zM75.68,75.68v6.88h44.72v-6.88zM51.6,96.32v6.88h10.32v-6.88zM75.68,96.32v6.88h44.72v-6.88zM51.6,116.96v6.88h10.32v-6.88zM75.68,116.96v6.88h44.72v-6.88z"></path></g></g></svg>
</template>

<script>
export default {
};
</script>
