<template>
  <a :href="href" class="inline-flex gap-2 content-center items-center transition duration-150 ease-in-out" aria-label="View your shopping cart">

  <div class="relative">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="1.8rem"
      height="1.8rem"
      viewBox="0 0 172 172"
      style="fill: #000000"
    >
      <g
        fill="none"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <path d="M0,172v-172h172v172z" fill="none"></path>
        <g :fill="colour">
          <path
            d="M6.235,6.88c-0.1075,0.02688 -0.215,0.06719 -0.3225,0.1075c-3.34594,0.47031 -5.9125,3.30563 -5.9125,6.7725c0,3.80281 3.07719,6.88 6.88,6.88c3.80281,0 6.88,-3.07719 6.88,-6.88h16.0175c4.28656,0 6.51719,0.88688 8.17,2.365c1.62594,1.46469 2.94281,3.93719 4.085,7.74l27.52,111.0475c1.03469,3.93719 2.06938,7.99531 4.945,11.2875c1.19594,1.37063 2.70094,2.48594 4.515,3.3325c-2.01562,2.39188 -3.3325,5.36156 -3.3325,8.7075c0,7.56531 6.19469,13.76 13.76,13.76c7.56531,0 13.76,-6.19469 13.76,-13.76c0,-2.52625 -0.73906,-4.8375 -1.935,-6.88h21.07c-1.19594,2.0425 -1.935,4.35375 -1.935,6.88c0,7.56531 6.19469,13.76 13.76,13.76c7.56531,0 13.76,-6.19469 13.76,-13.76c0,-3.53406 -1.43781,-6.69187 -3.655,-9.1375c0.38969,-1.04812 0.22844,-2.23062 -0.40312,-3.14437c-0.645,-0.92719 -1.69313,-1.47813 -2.82188,-1.47813h-52.5675c-5.30781,0 -7.47125,-1.12875 -8.9225,-2.795c-1.43781,-1.65281 -2.31125,-4.54187 -3.3325,-8.385v-0.1075l-2.365,-9.3525h63.1025c1.43781,0 2.74125,-0.90031 3.225,-2.2575l24.725,-65.36c0.40313,-1.04812 0.25531,-2.23062 -0.38969,-3.15781c-0.645,-0.92719 -1.70656,-1.47812 -2.83531,-1.46469h-105.6725l-7.31,-29.3475c0,-0.06719 0,-0.14781 0,-0.215c-1.31687,-4.43437 -3.02344,-8.2775 -6.1275,-11.0725c-3.10406,-2.795 -7.47125,-4.085 -12.7925,-4.085h-22.8975c-0.1075,0 -0.215,0 -0.3225,0c-0.1075,0 -0.215,0 -0.3225,0zM57.7275,58.48h99.0075l-22.145,58.48h-62.35zM89.44,151.36c3.84313,0 6.88,3.03688 6.88,6.88c0,3.84313 -3.03687,6.88 -6.88,6.88c-3.84312,0 -6.88,-3.03687 -6.88,-6.88c0,-3.84312 3.03688,-6.88 6.88,-6.88zM134.16,151.36c3.84313,0 6.88,3.03688 6.88,6.88c0,3.84313 -3.03687,6.88 -6.88,6.88c-3.84312,0 -6.88,-3.03687 -6.88,-6.88c0,-3.84312 3.03688,-6.88 6.88,-6.88z"
          ></path>
        </g>
      </g>
    </svg>
    <span class="admin_nav_secondary_nav_link absolute text-center text-white bg-secondary-500 text-on-secondary-500 rounded-full flex items-center justify-center" style="font-size: .5rem;top: .0rem;left: .8rem;width: 1.1rem;height: 1.1rem;" v-text="counter" v-if="counter"></span>
  </div>
</a>
</template>

<script>
import Storer from "../services/storer";
import Modal from "./Modal.vue"
import ModalSubmitButtonRow from "./ModalSubmitButtonRow.vue"
import FormInput from "./FormInput.vue";

export default {
  components: {
    Modal,
    ModalSubmitButtonRow,
    FormInput,
  },
  props: {
    company_name: String,
    colour: String,
    href: String,
  },  
  data() {
    return {
      counter: 0,
      cart: null,
    };
  },
  methods: {
    setCounter() {
      this.cart = Storer.retrieve(`${this.company_name.replaceAll(" ", "_").toLowerCase()}_cart`);
      this.counter =
        this.cart?.items?.reduce((total, item) => (total += item.quantity), 0) ?? 0;
    },
    refreshCart()
    {
      if(!this.cart?.id)
      {
        return
      }
      axios({
        method: "post",
        url: `/cart/${this.cart.id}/refresh`,
        data: {
          items: this.cart
        },
      })
        .then((response) => {
          this.cart = response.data.cart
          this.setCounter()
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            Storer.delete(`${this.company_name.replaceAll(" ", "_").toLowerCase()}_cart`);
            this.setCounter()
          }
        });
    },
  },
  mounted() {
    this.cart = Storer.retrieve(`${this.company_name.replaceAll(" ", "_").toLowerCase()}_cart`);
    if(this.cart && !this.cart['id'])
    {
      Storer.delete(`${this.company_name.replaceAll(" ", "_").toLowerCase()}_cart`);
      this.cart = null
    }
    else 
    {
      this.refreshCart()
    }
    this.eventBus.on("cartModified", () => this.setCounter());
  },
};
</script>
