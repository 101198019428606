<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 172 172"
    style="fill: inherit;"
  >
    <g
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="inherit">
        <path
          d="M27.52,6.88v13.76h-13.76v144.48h144.48v-116.96h-55.04v-27.52h-13.76v-13.76zM34.4,13.76h48.16v13.76h13.76v130.72h-30.96v-24.08h-13.76v24.08h-30.96v-130.72h13.76zM34.4,34.4v6.88h6.88v-6.88zM48.16,34.4v6.88h6.88v-6.88zM61.92,34.4v6.88h6.88v-6.88zM75.68,34.4v6.88h6.88v-6.88zM34.4,51.6v13.76h6.88v-13.76zM48.16,51.6v13.76h6.88v-13.76zM61.92,51.6v13.76h6.88v-13.76zM75.68,51.6v13.76h6.88v-13.76zM103.2,55.04h48.16v103.2h-48.16zM110.08,61.92v6.88h6.88v-6.88zM123.84,61.92v6.88h6.88v-6.88zM137.6,61.92v6.88h6.88v-6.88zM34.4,72.24v13.76h6.88v-13.76zM48.16,72.24v13.76h6.88v-13.76zM61.92,72.24v13.76h6.88v-13.76zM75.68,72.24v13.76h6.88v-13.76zM110.08,75.68v6.88h6.88v-6.88zM123.84,75.68v6.88h6.88v-6.88zM137.6,75.68v6.88h6.88v-6.88zM110.08,89.44v6.88h6.88v-6.88zM123.84,89.44v6.88h6.88v-6.88zM137.6,89.44v6.88h6.88v-6.88zM34.4,92.88v13.76h6.88v-13.76zM48.16,92.88v13.76h6.88v-13.76zM61.92,92.88v13.76h6.88v-13.76zM75.68,92.88v13.76h6.88v-13.76zM110.08,103.2v6.88h6.88v-6.88zM123.84,103.2v6.88h6.88v-6.88zM137.6,103.2v6.88h6.88v-6.88zM34.4,113.52v13.76h6.88v-13.76zM48.16,113.52v13.76h6.88v-13.76zM61.92,113.52v13.76h6.88v-13.76zM75.68,113.52v13.76h6.88v-13.76zM110.08,116.96v6.88h6.88v-6.88zM123.84,116.96v6.88h6.88v-6.88zM137.6,116.96v6.88h6.88v-6.88zM110.08,130.72v6.88h6.88v-6.88zM123.84,130.72v6.88h6.88v-6.88zM137.6,130.72v6.88h6.88v-6.88zM34.4,134.16v17.2h6.88v-17.2zM75.68,134.16v17.2h6.88v-17.2zM110.08,144.48v6.88h6.88v-6.88zM123.84,144.48v6.88h6.88v-6.88zM137.6,144.48v6.88h6.88v-6.88z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
};
</script>
