<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 172 172"
    style="fill: inherit;"
  >
    <g
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="inherit">
        <path
          d="M0,24.08v110.08h172v-110.08zM6.88,30.96h158.24v96.32h-158.24zM20.64,44.72v30.96h61.92v-30.96zM89.44,44.72v30.96h61.92v-30.96zM27.52,51.6h48.16v17.2h-48.16zM96.32,51.6h48.16v17.2h-48.16zM20.64,82.56v30.96h61.92v-30.96zM89.44,82.56v30.96h61.92v-30.96zM27.52,89.44h48.16v17.2h-48.16zM96.32,89.44h48.16v17.2h-48.16zM36.8725,141.04c-1.89469,0.26875 -3.225,2.02906 -2.95625,3.92375c0.26875,1.89469 2.02906,3.225 3.92375,2.95625h96.32c1.23625,0.01344 2.39188,-0.63156 3.02344,-1.70656c0.61813,-1.075 0.61813,-2.39187 0,-3.46687c-0.63156,-1.075 -1.78719,-1.72 -3.02344,-1.70656h-96.32c-0.1075,0 -0.215,0 -0.3225,0c-0.1075,0 -0.215,0 -0.3225,0c-0.1075,0 -0.215,0 -0.3225,0z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
};
</script>
