<script setup lang="ts">
import { Ref, computed, ref } from 'vue';
import ChevronLeftIcon from "./ChevronLeftIcon.vue";
import ChevronRightIcon from "./ChevronRightIcon.vue";
import CustomerOrderIcon from "./icons/CustomerOrderIcon.vue";
import BrandIcon from "./icons/BrandIcon.vue";
import CategoryIcon from "./icons/CategoryIcon.vue";
import CompanyIcon from "./icons/CompanyIcon.vue";
import ConsignmentIcon from "./icons/ConsignmentIcon.vue";
import CouponIcon from "./icons/CouponIcon.vue";
import DashboardIcon from "./icons/DashboardIcon.vue";
import FacebookIcon from "./icons/FacebookIcon.vue";
import GiftCertificateIcon from "./icons/GiftCertificateIcon.vue";
import InventoryItemIcon from "./icons/InventoryItemIcon.vue";
import InventorySetIcon from "./icons/InventorySetIcon.vue";
import LogIcon from "./icons/LogIcon.vue";
import NoteIcon from "./icons/NoteIcon.vue";
import PaymentIcon from "./icons/PaymentIcon.vue";
import PreorderIcon from "./icons/PreorderIcon.vue";
import ProductIcon from "./icons/ProductIcon.vue";
import ProductSetIcon from "./icons/ProductSetIcon.vue";
import ProductSkuIcon from "./icons/ProductSkuIcon.vue";
import SupplierOrderIcon from "./icons/SupplierOrderIcon.vue";
import SpecialIcon from "./icons/SpecialIcon.vue";
import StoreIcon from "./icons/StoreIcon.vue";
import WarehouseIcon from "./icons/WarehouseIcon.vue";
import TransactionIcon from "./icons/TransactionIcon.vue";
import TransportationCompanyIcon from "./icons/TransportationCompanyIcon.vue";
import SupplierIcon from "./icons/SupplierIcon.vue";
import UserIcon from "./icons/UserIcon.vue";
import TagIcon from "./icons/TagIcon.vue";
import ServiceIcon from "./icons/ServiceIcon.vue";
import PageItemIcon from "./icons/PageItemIcon.vue";
import WebIcon from "./icons/WebIcon.vue";
import XeroIcon from "./icons/XeroIcon.vue";
import BackButton from "./menu/BackButton.vue"
import MenuItem from "./menu/MenuItem.vue"

  const props = defineProps<{
      user: Object 
      categories: Array<any>
      pages:  Array<any>
      customer_orders: Number 
      customer_supports: Number
      unconsigned_items:  Number
      navigation_settings: any
      current_note_alerts_count: Number
      preorders_count: Number
    }>()

const modal = ref()

const toggleModal = () => {
    if(modal?.value?.open) {
        modal?.value?.classList.add("hide")
        setTimeout(() => modal?.value?.close(), 200)
        
    } else {
        modal?.value?.classList.remove("hide")
        modal?.value?.showModal()
    }
}

defineExpose({
  toggleModal
})

const page = window.location.href
const menu = ref("main") as Ref<string>
const selectedCategory = ref(null) as Ref<any> 
const selectedPage = ref(null) as Ref<any>
const admin = [
        { name: "Dashboards", route: "/admin/dashboard", icon: "dashboard-icon" },
        {
          name: "Customer Support",
          route: "/admin/customer_support",
          icon: "support-icon",
          number: props.customer_supports,
          permission: ['customer_support'],
        },
        {
          name: "Customer Orders",
          route: "/admin/customer_orders",
          icon: "customer-order-icon",
          number: props.customer_orders,
          permission: ['customer_order'],
        },
        {
          name: "Carts",
          route: "/admin/carts",
          icon: "cart-icon",
          permission: ['cart'],
        },
        {
          name: "Payments",
          route: null,
          icon: "payment-icon",
          menu: 'payments',
          permission: ['payment'],
        },
        {
          name: "Deliveries",
          route: null,
          icon: "transportation-company-icon",
          menu: 'deliveries',
          permission: ['consignment', 'transportation_company'],
        },
        {
          name: "Preorders",
          route: "/admin/preorders",
          icon: "preorder-icon",
          permission: ['preorder'],
        },
        {
          name: "Notes",
          route: "/admin/notes",
          icon: "note-icon",
          permission: ['note'],
          number: props.current_note_alerts_count
        },
        {
          name: "Merchandise",
          route: null,
          icon: "cart-icon",
          menu: 'merchandise',
          permission: ['product', 'product_sku', 'offered_service', 'gift_certificate', 'purchase_addon', 'review'],
        },
        {
          name: "Inventory",
          route: null,
          icon: "warehouse-inventory-icon",
          menu: 'inventory',
          permission: ['inventory_item', 'inventory_set'], 
        },
        {
          name: "Sales",
          route: "/admin/sales",
          icon: "payment-icon",
          permission: ['sales'],
        },
        {
          name: "Promotions",
          route: null,
          icon: "promotion-icon",
          menu: 'promotion',
          permission: ['special', 'coupon', 'newsletter'], 
        },
        {
          name: "Supply",
          route: null,
          icon: "supplier-icon",
          menu: 'supply',
          permission: ['supplier', 'supplier_order'], 
        },
        { 
          name: "Users", 
          route: "/admin/users", 
          icon: "user-icon",
          permission: ['user'], 
        },
        { 
          name: "Stores", 
          route: "/admin/stores", 
          icon: "store-icon",
          permission: ['store'], 
        },
        {
          name: "Product Groupings",
          route: null,
          icon: "tags-icon",
          menu: 'product_grouping',
          permission: ['category', 'tag', 'brand'], 
        },
        {
          name: "Website Design",
          route: null,
          icon: "design-icon",
          menu: 'design',
          permission: ['page'], 
        },
        { 
          name: "Blog", 
          route: "/admin/blog_articles", 
          icon: "blog-icon",
          permission: ['blog_article'], 
        },        
        { 
          name: "Facebook", 
          route: "/admin/facebook", 
          icon: "facebook-icon",
          permission: ['facebook'], 
        },
        { 
          name: "Google", 
          route: "/admin/google", 
          icon: "google-icon",
          permission: ['google'], 
        },
        {
          name: "Accounting",
          route: null,
          icon: "accounting-report-icon",
          menu: 'accounting',
          permission: [], 
        },
        {
          name: "Alternate Sales Channels",
          route: null,
          icon: "accounting-report-icon",
          menu: 'sales_channels',
          permission: [], 
        },
        
        {
          name: "Settings",
          route: null,
          icon: "service-icon",
          menu: 'settings',
          permission: ['website_setting', 'service', 'company'], 
        },
        { 
          name: "Documents", 
          route: "/admin/documents", 
          icon: "documents-icon",
          permission: ['document'], 
        },
        { 
          name: "Logs", 
          route: "/admin/logs", 
          icon: "log-icon",
          permission: ['log'], 
        },
        { 
          name: "Preorder Templates", 
          route: "/admin/preorder_templates", 
          icon: "",
          permission: ['preorder_template'], 
        },
        { 
          name: "Businesses", 
          route: "/admin/businesses", 
          icon: "",
          permission: ['business'], 
        },
        { 
          name: "Competitors", 
          route: "/admin/competitors", 
          icon: "",
          permission: ['competitor'], 
        },
        { 
          name: "SEO", 
          route: "/admin/seo", 
          icon: "",
          permission: [], 
        },
        { 
          name: "Guides", 
          route: "/admin/guides", 
          icon: "",
          permission: [], 
        },
        { 
          name: "Jobs", 
          route: "/admin/jobs", 
          icon: "",
          permission: [], 
        },
        { 
          name: "Backups", 
          route: "/admin/database_backups", 
          icon: "",
          permission: [], 
        },
      ]
  const accounting = [
        { 
          name: "Reports", 
          route: "/admin/accounting_reports", 
          icon: "accounting-report-icon",
          permission: ['xero'], 
        },
        {  
          name: "Xero", 
          route: "/admin/xero", 
          icon: "xero-icon",
          permission: ['xero'], 
        },
      ]
      const sales_channels = [
        { 
          name: "Trade Me", 
          route: "/admin/trade_me", 
          icon: "",
          permission: ['trade_me'], 
        },
      ]
      const deliveries = [
        {
          name: "Unconsignmented Customer Order Items",
          route: "/admin/unconsigned_customer_orders",
          icon: "consignment-icon",
          permission: ['consignment'], 
          number: props.unconsigned_items
        },
        {
          name: "Consignments",
          route: "/admin/consignments",
          icon: "consignment-icon",
          permission: ['consignment'], 
        },
        {
          name: "Stock Transfers",
          route: "/admin/stock_transfers",
          icon: "consignment-icon",
          permission: ['stock_transfer'], 
        },
        {
          name: "Transportation Companies",
          route: "/admin/transportation_companies",
          icon: "transportation-company-icon",
          permission: ['transportation_company'], 
        },
        {
          name: "Shipping Rules",
          route: "/admin/shipping_rules",
          icon: "shippig-rule-icon",
          permission: ['shipping_rule'], 
        },
        
      ]
      const payments = [
        {
          name: "Payments",
          route: "/admin/payments",
          icon: "payment-icon",
          permission: ['payment'], 
        },
        {
          name: "Online Transactions",
          route: "/admin/online_transactions",
          icon: "transaction-icon",
          permission: ['payment'], 
        },
        
      ]
      const merchandise = [
        { 
          name: "Products", 
          route: "/admin/products", 
          icon: "product-icon",
          permission: ['product'], 
        },
        {
          name: "Product Sets",
          route: "/admin/product_sets",
          icon: "product-set-icon",
          permission: ['product'], 
        },
        {
          name: "Product SKUs",
          route: "/admin/product_skus",
          icon: "product-sku-icon",
          permission: ['product_sku'], 
        },
        { 
          name: "Services", 
          route: "/admin/offered_services", 
          icon: "service-icon",
          permission: ['service'], 
        },
        { 
          name: "Service Components", 
          route: "/admin/offered_service_components", 
          icon: "service-icon",
          permission: ['service_component'], 
        },
        { 
          name: "Gift Certificates", 
          route: "/admin/gift_certificates", 
          icon: "gift-certificate-icon",
          permission: ['gift_certificate'], 
        },
        { 
          name: "Purchase Addons", 
          route: "/admin/purhase_addons", 
          icon: "",
          permission: ['product'], 
        },
        { 
          name: "Product Shipping Details", 
          route: "/admin/product_shipping_details", 
          icon: "",
          permission: ['product_shipping_details'], 
        },
        { 
          name: "Product Warranty Details", 
          route: "/admin/product_warranty_details", 
          icon: "",
          permission: ['product_warranty_details'], 
        },
        { 
          name: "Reviews", 
          route: "/admin/reviews", 
          icon: "review-icon",
          permission: ['review'], 
        },
      ]
   const inventory = [
        {
          name: "Current Inventory",
          route: "/admin/inventory",
          icon: "inventory-icon",
          permission: ['inventory_item'], 
        },
        {
          name: "Inventory Sets",
          route: "/admin/inventory_sets",
          icon: "inventory-set-icon",
          permission: ['inventory_set'], 
        },
        {
          name: "Inventory Items",
          route: "/admin/inventory_items",
          icon: "inventory-item-icon",
          permission: ['inventory_item'], 
        },
        {
          name: "Warehouses",
          route: "/admin/warehouses",
          icon: "warehouse-icon",
          permission: ['warehouse'], 
        },
        {
          name: "Alerts",
          route: "/admin/inventory_alerts",
          icon: "alert-icon",
          permission: ['inventory_alert'], 
        },
      ]
  const design = [
        {
          name: "Designs",
          route: "/admin/designs",
          icon: "",
          permission: ['design'], 
        },  
        {
          name: "Home Page",
          route: "/admin/home_page",
          icon: "home-icon",
          permission: ['page'], 
        },
        {
          name: "Pages",
          route: "/admin/pages",
          icon: "page-icon",
          permission: ['page'], 
        },
        {
          name: "Components",
          route: "/admin/components",
          icon: "",
          permission: ['component'], 
        },
        {
          name: "Page Settings",
          route: "/admin/page_settings",
          icon: "",
          permission: ['page_setting'], 
        },  
        {
          name: "Themes",
          route: "/admin/themes",
          icon: "",
          permission: ['theme'], 
        },
        {
          name: "Images",
          route: "/admin/images",
          icon: "",
          permission: ['image'], 
        },  
      ]
  const promotions = [
        { 
          name: "Specials", 
          route: "/admin/specials", 
          icon: "special-icon",
          permission: ['special'], 
        },
        { 
          name: "Coupons", 
          route: "/admin/coupons", 
          icon: "coupon-icon",
          permission: ['coupon'],  
        },
        { 
          name: "Newsletters", 
          route: "/admin/newsletters", 
          icon: "newsletter-icon",
          permission: ['newsletter'],  
        },
      ]
  const product_groupings = [
        {
          name: "Categories",
          route: "/admin/categories",
          icon: "category-icon",
          permission: ['category'], 
        },
        { 
          name: "Tags", 
          route: "/admin/tags", 
          icon: "tag-icon",
          permission: ['tag'], 
        },
        { 
          name: "Brands", 
          route: "/admin/brands", 
          icon: "brand-icon",
          permission: ['brand'], 
        },
        { 
          name: "Sorters", 
          route: "/admin/sorters", 
          icon: "",
          permission: ['sorter'], 
        },
        { 
          name: "Shop Filters", 
          route: "/admin/shop_filters", 
          icon: "",
          permission: ['shop_filter'], 
        },
      ]
   const website_settings = [
        { 
          name: "Website Settings", 
          route: "/admin/settings", 
          icon: "web-icon",
          permission: ['setting'], 
        },
        { 
          name: "Browser Settings", 
          route: "/admin/settings/browser", 
          icon: "",
          permission: ['setting'], 
        },
        { 
          name: "Storage Settings", 
          route: "/admin/settings/storage", 
          icon: "",
          permission: ['setting'], 
        },
        { 
          name: "Colour Settings", 
          route: "/admin/settings/colour", 
          icon: "",
          permission: ['setting'], 
        },
        { 
          name: "Pages Settings", 
          route: "/admin/settings/pages", 
          icon: "",
          permission: ['setting'], 
        },
        { 
          name: "PDF Settings", 
          route: "/admin/settings/pdf", 
          icon: "",
          permission: ['setting'], 
        },
        { 
          name: "Cart Settings", 
          route: "/admin/settings/cart", 
          icon: "",
          permission: ['setting'], 
        },
        { 
          name: "Email Settings", 
          route: "/admin/settings/email", 
          icon: "",
          permission: ['setting'], 
        },
        { 
          name: "Navigation Settings", 
          route: "/admin/settings/navigation", 
          icon: "",
          permission: ['setting'], 
        },
        { 
          name: "Transaction Settings", 
          route: "/admin/settings/transaction", 
          icon: "",
          permission: ['setting'], 
        },
        { 
          name: "Shipping Settings", 
          route: "/admin/settings/shipping", 
          icon: "",
          permission: ['setting'], 
        },
        { 
          name: "Newsletter Settings", 
          route: "/admin/settings/newsletter", 
          icon: "",
          permission: ['setting'], 
        },
        { 
          name: "Footer Settings", 
          route: "/admin/settings/footer", 
          icon: "",
          permission: ['setting'], 
        },
        { 
          name: "Affiliate Settings", 
          route: "/admin/settings/affiliate", 
          icon: "",
          permission: ['setting'], 
        },
        { 
          name: "Email Templates", 
          route: "/admin/email_templates", 
          icon: "email-icon",
          permission: ['email_template'], 
        },
        { 
          name: "Services", 
          route: "/admin/services", 
          icon: "service-icon",
          permission: ['service'], 
        },
        { 
          name: "Company Details", 
          route: "/admin/company", 
          icon: "company-icon",
          permission: ['company'], 
        },
        { 
          name: "Loyalty Program", 
          route: "/admin/loyalty_program", 
          icon: "reward-icon",
          permission: ['loyalty_program'], 
        },
        { 
          name: "Roles", 
          route: "/admin/roles", 
          icon: "",
          permission: ['role'], 
        },
      ]
const supply = [
        { 
          name: "Suppliers", 
          route: "/admin/suppliers", 
          icon: "supplier-icon",
          permission: ['supplier'], 
        },
        {
          name: "Supplier Orders",
          route: "/admin/supplier_orders",
          icon: "supplier-order-icon",
          permission: ['supplier_order'], 
        },
        
      ]
const iconSize = props.navigation_settings.settings.main.primary_link.font_size
const iconColour = props.navigation_settings.settings.main.primary_link.colour.hex
const font = props.navigation_settings.settings.main.primary_link_font

const level1Categories = computed(() => {
  return props.categories.filter((c) => c.level === 1);
})
const level1Category = computed(() => {
  return selectedCategory.value?.['level'] === 2
    ? level1Categories?.find(
        (c) => c.id === selectedCategory.value?.['category_id']
      )
    : null;
})

const showCategory = (category) => {
    if (category.categories?.length) {
      selectedCategory.value = category;
      menu.value = "category";
    }
  }
const goBackFromCategory = () => {
      if (!selectedCategory.value || selectedCategory.value?.['level'] === 1) {
        menu.value = "main";
        selectedCategory.value = null;
      } else {
        showCategory(level1Category.value);
      }
    }
const showPage = (page) => {
  if (page.pages?.length) {
    selectedPage.value = page;
    menu.value = "page";
  }
}
const goBackFromPage = () => {
      menu.value = "main";
      selectedPage.value = null;
    }
const closeMenu = () => {
      toggleModal()
      menu.value = "main";
    }

</script>

<template>
  <button
        type="button"
        class="hamburger"
        @click.prevent="toggleModal()"
    >
      <svg
      :stroke="navigation_settings.settings.main.primary_link.colour.hex"
      fill="none"
      width="2.15rem"
      height="2.15rem"
      viewBox="0 0 24 24"
      aria-label="Menu"
    >
      <path
        class="inline-flex"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
    </button>
<dialog ref="modal" class="hamburger-menu p-0 h-screen m-0 w-full shadow-xl overflow-hidden">
   
    <div class="h-screen w-full hamburger overflow-y-auto relative" >
      <button class="fixed top-2 left-2"  @click="closeMenu">
        <svg
          :stroke="navigation_settings.settings.main.primary_link.colour.hex"
          fill="none"
          width="2.15rem"
          height="2.15rem"
          viewBox="0 0 24 24"
         
        >
          <path
            class="inline-flex"
            stroke-linecap="round"  
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <ul class="" v-if="menu === 'main'">
        <li class="hamburger_nav_list_item" >
          <a :href="`/`" :class="font">
            Home
          </a>
        </li>
        <li class="hamburger_nav_list_item" >
          <a :href="`/shop`" :class="font">
            All Products
          </a>
        </li>
        <li class="hamburger_nav_list_item" >
          <a :href="`/shop/specials`" :class="font">
            Specials
          </a>
        </li>
        <li
          @click="menu = 'categories'"
          class="hamburger_nav_list_item" 
        >
        <button class="inline-flex gap-2 items-center">
          <chevron-left-icon
          :style="`height:${iconSize};width:${iconSize};fill:${iconColour};`"
          ></chevron-left-icon>
          <span  :class="font">Shop by Category</span>
        </button>
        </li>
        <li
          v-for="page in pages"
          :key="page.id"
          @click="showPage(page)"
          class="hamburger_nav_list_item"
        >
        <button class="inline-flex gap-2 items-center" v-if="page.pages?.length">
          <chevron-left-icon
            :style="`height:${iconSize};width:${iconSize};fill:${iconColour};`"
          ></chevron-left-icon>
        </button>
          <span v-if="page.pages?.length">{{ page.name }}</span>
          <a :href="`/${page.slug}`" :class="font" v-else>{{
            page.name
          }}</a>
        </li>
        <li
          v-if="user && user.staff"
          class="hamburger_nav_list_item"
          :class="font"
          @click="menu = 'admin'"
        >
        <button class="inline-flex gap-2 items-center">
          <chevron-left-icon
            :style="`height:${iconSize};width:${iconSize};fill:${iconColour};`"
          ></chevron-left-icon
          >Admin
        </button>
        </li>
        <slot></slot>
      </ul>
      <ul class="" v-if="menu === 'category'">
        <back-button :size="iconSize" :colour="iconColour" @clicked="goBackFromCategory()"></back-button>
        <menu-item
          :expandable="false"
          :link="`/shop/categories/${level1Category.slug}`"
          :name="level1Category.name"
          :icon="null"
          :number="null"
          :size="iconSize"
          :colour="iconColour"
          :navigation_settings="navigation_settings"
          :font="font"
          v-if="level1Category"
        />
        <menu-item
          :expandable="false"
          :link="`/shop/categories/${selectedCategory?.slug}`"
          :name="selectedCategory.name"
          :icon="null"
          :number="null"
          :size="iconSize"
          :colour="iconColour"
          :navigation_settings="navigation_settings"
          :font="font"
        />
        <menu-item
          v-for="category in  selectedCategory?.categories ?? []"
          :key="category.id"
          @clicked="showCategory(category)"
          :expandable="!!category.categories?.length"
          :link="`/shop/categories/${category.slug}`"
          :name="category.name"
          :icon="null"
          :number="null"
          :size="iconSize"
          :colour="iconColour"
          :navigation_settings="navigation_settings"
          :font="font"
        />
      </ul>

      <ul class="" v-if="menu === 'categories'">
        <back-button :size="iconSize" :colour="iconColour" @clicked="goBackFromCategory()"></back-button>
        <menu-item
          v-for="category in level1Categories"
          :key="category.id"
          @clicked="showCategory(category)"
          :expandable="!!category.categories?.length"
          :link="`/shop/categories/${category.slug}`"
          :name="category.name"
          :icon="null"
          :number="null"
          :size="iconSize"
          :colour="iconColour"
          :navigation_settings="navigation_settings"
          :font="font"
        />
      </ul>
      <ul class="" v-if="menu === 'page'">
        <back-button :size="iconSize" :colour="iconColour" @clicked="goBackFromPage()"></back-button>
        <menu-item
          :expandable="false"
          :link="`/${selectedPage.slug}`"
          :name="selectedPage.name"
          :icon="null"
          :number="null"
          :size="iconSize"
          :colour="iconColour"
          :navigation_settings="navigation_settings"
          :font="font"
          v-if="selectedPage"
        />
        <menu-item
          v-for="subpage in selectedPage.pages"
          :key="subpage.id"
          :expandable="false"
          :link="`/${subpage.slug}`"
          :name="subpage.name"
          :icon="null"
          :number="null"
          :size="iconSize"
          :colour="iconColour"
          :navigation_settings="navigation_settings"
          :font="font"
        />
      </ul>
      <ul class="" v-if="menu === 'admin'">
        <back-button :size="iconSize" :colour="iconColour" @clicked="menu = 'main'"></back-button>
        <template 
          v-for="route in admin"
          :key="route.name"
        >
          <menu-item
            :expandable="!route.route"
            :link="route.route"
            :name="route.name"
            :icon="route.icon"
            :size="iconSize"
            :colour="iconColour"
            :number="route.number"
            :user="user"
            :permission="route.permission"
            :navigation_settings="navigation_settings"
            :font="font"
            @clicked="menu = route.menu"
          />
        </template>
      </ul>
      <ul class="" v-if="menu === 'supply'">
        <back-button :size="iconSize" :colour="iconColour" @clicked="menu = 'admin'"></back-button>
        <menu-item
          v-for="route in supply"
          :key="route.name"
          :expandable="false"
          :link="route.route"
          :name="route.name"
          :icon="route.icon"
          :number="route.number"
          :size="iconSize"
          :colour="iconColour"
          :user="user"
          :permission="route.permission"
          :navigation_settings="navigation_settings"
          :font="font"
        />
      </ul>
      <ul class="" v-if="menu === 'inventory'">
        <back-button :size="iconSize" :colour="iconColour" @clicked="menu = 'admin'"></back-button>
        <menu-item
          v-for="route in inventory"
          :key="route.name"
          :expandable="false"
          :link="route.route"
          :name="route.name"
          :icon="route.icon"
          :number="route.number"
          :size="iconSize"
          :colour="iconColour"
          :user="user"
          :permission="route.permission"
          :navigation_settings="navigation_settings"
          :font="font"
        />
      </ul>
      <ul class="" v-if="menu === 'product_grouping'">
        <back-button :size="iconSize" :colour="iconColour" @clicked="menu = 'admin'"></back-button>
        <menu-item
          v-for="route in product_groupings"
          :key="route.name"
          :expandable="false"
          :link="route.route"
          :name="route.name"
          :icon="route.icon"
          :number="route.number"
          :size="iconSize"
          :colour="iconColour"
          :user="user"
          :permission="route.permission"
          :navigation_settings="navigation_settings"
          :font="font"
        />
      </ul>
      <ul class="" v-if="menu === 'promotion'">
        <back-button :size="iconSize" :colour="iconColour" @clicked="menu = 'admin'"></back-button>
        <menu-item
          v-for="route in promotions"
          :key="route.name"
          :expandable="false"
          :link="route.route"
          :name="route.name"
          :icon="route.icon"
          :number="route.number"
          :size="iconSize"
          :colour="iconColour"
          :user="user"
          :permission="route.permission"
          :navigation_settings="navigation_settings"
          :font="font"
        />
      </ul>
      <ul class="" v-if="menu === 'merchandise'">
        <back-button :size="iconSize" :colour="iconColour" @clicked="menu = 'admin'"></back-button>
        <menu-item
          v-for="route in merchandise"
          :key="route.name"
          :expandable="false"
          :link="route.route"
          :name="route.name"
          :icon="route.icon"
          :number="route.number"
          :size="iconSize"
          :colour="iconColour"
          :user="user"
          :permission="route.permission"
          :navigation_settings="navigation_settings"
          :font="font"
        />
      </ul>
      <ul class="" v-if="menu === 'payments'">
        <back-button :size="iconSize" :colour="iconColour" @clicked="menu = 'admin'"></back-button>
        <menu-item
          v-for="route in payments"
          :key="route.name"
          :expandable="false"
          :link="route.route"
          :name="route.name"
          :icon="route.icon"
          :number="route.number"
          :size="iconSize"
          :colour="iconColour"
          :user="user"
          :permission="route.permission"
          :navigation_settings="navigation_settings"
          :font="font"
        />
      </ul>
      <ul class="" v-if="menu === 'design'">
        <back-button :size="iconSize" :colour="iconColour" @clicked="menu = 'admin'"></back-button>
        <menu-item
          v-for="route in design"
          :key="route.name"
          :expandable="false"
          :link="route.route"
          :name="route.name"
          :icon="route.icon"
          :number="route.number"
          :size="iconSize"
          :colour="iconColour"
          :user="user"
          :permission="route.permission"
          :navigation_settings="navigation_settings"
          :font="font"
        />
      </ul>
      <ul class="" v-if="menu === 'deliveries'">
        <back-button :size="iconSize" :colour="iconColour" @clicked="menu = 'admin'"></back-button>
        <menu-item
          v-for="route in deliveries"
          :key="route.name"
          :expandable="false"
          :link="route.route"
          :name="route.name"
          :icon="route.icon"
          :number="route.number"
          :size="iconSize"
          :colour="iconColour"
          :user="user"
          :permission="route.permission"
          :navigation_settings="navigation_settings"
          :font="font"
        />
      </ul>
      <ul class="" v-if="menu === 'settings'">
        <back-button :size="iconSize" :colour="iconColour" @clicked="menu = 'admin'"></back-button>
        <menu-item
          v-for="route in website_settings"
          :key="route.name"
          :expandable="false"
          :link="route.route"
          :name="route.name"
          :icon="route.icon"
          :number="route.number"
          :size="iconSize"
          :colour="iconColour"
          :user="user"
          :permission="route.permission"
          :navigation_settings="navigation_settings"
          :font="font"
        />
      </ul>
      <ul class="" v-if="menu === 'accounting'">
        <back-button :size="iconSize" :colour="iconColour" @clicked="menu = 'admin'"></back-button>
        <menu-item
          v-for="route in accounting"
          :key="route.name"
          :expandable="false"
          :link="route.route"
          :name="route.name"
          :icon="route.icon"
          :number="route.number"
          :size="iconSize"
          :colour="iconColour"
          :user="user"
          :permission="route.permission"
          :navigation_settings="navigation_settings"
          :font="font"
        />
      </ul>
      <ul class="" v-if="menu === 'sale_channels'">
        <back-button :size="iconSize" :colour="iconColour" @clicked="menu = 'admin'"></back-button>
        <menu-item
          v-for="route in sales_channels"
          :key="route.name"
          :expandable="false"
          :link="route.route"
          :name="route.name"
          :icon="route.icon"
          :number="route.number"
          :size="iconSize"
          :colour="iconColour"
          :user="user"
          :permission="route.permission"
          :navigation_settings="navigation_settings"
          :font="font"
        />
      </ul>
    </div>
</dialog>
</template>
<style>

dialog[open].hamburger-menu {
  max-width: 100vw;
  max-height: 100vh;
  animation: slide .3s ease normal;
}
dialog[open]::backdrop {
    animation: showBackdrop .1s ease normal;
}
dialog::backdrop {
    backdrop-filter: blur(2px);
}
dialog.hide {
    animation: hide .3s ease normal;
}
@keyframes slide{
    from {
        transform: translate(100vw, 0);
    }
    to {
        transform: translate(0, 0);
    }
}
@keyframes showBackdrop{
    from {
        backdrop-filter: blur(0px);
    }
    to {
        backdrop-filter: blur(2px);
    }
}
@keyframes hide{
  from {
        transform: translate(0, 0);
    }  
  to {
        transform: translate(100vw, 0);
    }
}
</style>